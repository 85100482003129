const endpoints = {
  navbar: '/data/navbar.json',
  home: '/data/home.json',
  social: '/data/social.json',
  about: '/data/about.json',
  projects: '/data/projects.json',
  changelog: '/data/changelog.json',
  routes: {
    "sections": [
      {
        "component": "About",
        "path": "/about",
        "headerTitle": "About"
      },
      {
        "component": "Projects",
        "path": "/projects",
        "headerTitle": "Projects"
      },
      {
        "component": "Project",
        "path": "/project/:id",
        "headerTitle": "Project"
      },
      {
        "component": "game/ChessPlay",
        "path": "/chess/play",
        "headerTitle": "Chess"
      },
      {
        "component": "game/ChessChange",
        "path": "/chess/change",
        "headerTitle": "Changelog"
      },
      {
        "component": "Chess",
        "path": "/chess",
        "headerTitle": "Chess"
      }
    ]
  }
};

export default endpoints;
